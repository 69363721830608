.home {
    text-align: center;
    display: grid;
    grid-template-columns: 30% auto;
    grid-gap: 16px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 55px;
    background-color: #f4f4f4;
}

.left {
    padding: 32px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start !important;
}

.right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start !important;
}

.avatar {
    height: 180px;
    width: 180px;
    border-radius: 90px;
}

.derived {
    height: 2px;
    width: 85%;
    background: darkgrey;
    border-radius: 2px;
    opacity: 0.3;
    margin-top: 32px;
    margin-bottom: 32px;
}

.description {
    height: 200px;
    width: 60%;
    border-radius: 8px;
    background: white;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: calc(8px + 2vmin);
    color: gray;
    margin: 32px;
    padding: 16px;
    border-width: 2px;
    border-color: cornflowerblue;
    border-style: dashed;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(8px + 2vmin);
    color: cornflowerblue;
}

.App-link {
    color: black;
}

.email-link {
    color: dimgray;
    font-style: italic;
    font-size: calc(4px + 2vmin);
    margin-top: 4pt;
}
